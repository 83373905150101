/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { PostFieldsFragmentDoc } from '../post/posts.generated';
import { BasicPageInfoFieldsFragmentDoc } from '../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type EscrowOrderFieldsFragment = {
  __typename?: 'EscrowOrder';
  id: string;
  message?: string | null;
  escrowScript: string;
  escrowAddress: string;
  nonce: string;
  releaseTxid?: string | null;
  returnTxid?: string | null;
  buyerDepositTx?: string | null;
  price: string;
  amount: number;
  amountCoinOrCurrency: number;
  createdAt: any;
  updatedAt: any;
  escrowOrderStatus: Types.EscrowOrderStatus;
  arbitratorAccount: {
    __typename?: 'Account';
    id: number;
    publicKey?: string | null;
    hash160?: string | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
  };
  buyerAccount: {
    __typename?: 'Account';
    id: number;
    publicKey?: string | null;
    hash160?: string | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
  };
  sellerAccount: {
    __typename?: 'Account';
    id: number;
    publicKey?: string | null;
    hash160?: string | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
  };
  moderatorAccount: {
    __typename?: 'Account';
    id: number;
    publicKey?: string | null;
    hash160?: string | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
  };
  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
  escrowOffer: {
    __typename?: 'Offer';
    postId: string;
    message: string;
    coinPayment?: string | null;
    localCurrency?: string | null;
  };
  escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
  dispute?: {
    __typename?: 'Dispute';
    id: string;
    createdBy: string;
    reason?: string | null;
    status: Types.DisputeStatus;
  } | null;
};

export type DisputeFieldsFragment = {
  __typename?: 'Dispute';
  id: string;
  reason?: string | null;
  createdBy: string;
  createdAt: any;
  updatedAt: any;
  disputeStatus: Types.DisputeStatus;
  escrowOrder: {
    __typename?: 'EscrowOrder';
    amount: number;
    id: string;
    buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
    sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
    offer: {
      __typename?: 'Offer';
      message: string;
      createdAt: any;
      country?: { __typename?: 'Country'; name?: string | null } | null;
      state?: { __typename?: 'State'; name?: string | null } | null;
      location?: {
        __typename?: 'Location';
        id: string;
        iso2?: string | null;
        country?: string | null;
        adminNameAscii?: string | null;
        cityAscii?: string | null;
      } | null;
    };
  };
};

export type TimelineItemFieldsFragment = {
  __typename?: 'TimelineItem';
  id: string;
  data:
    | {
        __typename: 'Dispute';
        id: string;
        reason?: string | null;
        createdBy: string;
        createdAt: any;
        updatedAt: any;
        disputeStatus: Types.DisputeStatus;
        escrowOrder: {
          __typename?: 'EscrowOrder';
          amount: number;
          id: string;
          buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
          sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
          offer: {
            __typename?: 'Offer';
            message: string;
            createdAt: any;
            country?: { __typename?: 'Country'; name?: string | null } | null;
            state?: { __typename?: 'State'; name?: string | null } | null;
            location?: {
              __typename?: 'Location';
              id: string;
              iso2?: string | null;
              country?: string | null;
              adminNameAscii?: string | null;
              cityAscii?: string | null;
            } | null;
          };
        };
      }
    | {
        __typename: 'EscrowOrder';
        id: string;
        message?: string | null;
        escrowScript: string;
        escrowAddress: string;
        nonce: string;
        releaseTxid?: string | null;
        returnTxid?: string | null;
        buyerDepositTx?: string | null;
        price: string;
        amount: number;
        amountCoinOrCurrency: number;
        createdAt: any;
        updatedAt: any;
        escrowOrderStatus: Types.EscrowOrderStatus;
        arbitratorAccount: {
          __typename?: 'Account';
          id: number;
          publicKey?: string | null;
          hash160?: string | null;
          telegramId?: string | null;
          telegramUsername?: string | null;
        };
        buyerAccount: {
          __typename?: 'Account';
          id: number;
          publicKey?: string | null;
          hash160?: string | null;
          telegramId?: string | null;
          telegramUsername?: string | null;
        };
        sellerAccount: {
          __typename?: 'Account';
          id: number;
          publicKey?: string | null;
          hash160?: string | null;
          telegramId?: string | null;
          telegramUsername?: string | null;
        };
        moderatorAccount: {
          __typename?: 'Account';
          id: number;
          publicKey?: string | null;
          hash160?: string | null;
          telegramId?: string | null;
          telegramUsername?: string | null;
        };
        paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
        escrowOffer: {
          __typename?: 'Offer';
          postId: string;
          message: string;
          coinPayment?: string | null;
          localCurrency?: string | null;
        };
        escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
        dispute?: {
          __typename?: 'Dispute';
          id: string;
          createdBy: string;
          reason?: string | null;
          status: Types.DisputeStatus;
        } | null;
      }
    | {
        __typename: 'Post';
        id: string;
        content: string;
        accountId: number;
        pageId?: string | null;
        tokenId?: string | null;
        repostCount: number;
        totalComments: number;
        commentableId?: string | null;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          publicKey?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
          telegramUsername?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        postOffer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
        } | null;
      };
};

export type TimelineQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type TimelineQuery = {
  __typename?: 'Query';
  timeline: {
    __typename?: 'TimelineItem';
    id: string;
    data:
      | {
          __typename: 'Dispute';
          id: string;
          reason?: string | null;
          createdBy: string;
          createdAt: any;
          updatedAt: any;
          disputeStatus: Types.DisputeStatus;
          escrowOrder: {
            __typename?: 'EscrowOrder';
            amount: number;
            id: string;
            buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
            sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
            offer: {
              __typename?: 'Offer';
              message: string;
              createdAt: any;
              country?: { __typename?: 'Country'; name?: string | null } | null;
              state?: { __typename?: 'State'; name?: string | null } | null;
              location?: {
                __typename?: 'Location';
                id: string;
                iso2?: string | null;
                country?: string | null;
                adminNameAscii?: string | null;
                cityAscii?: string | null;
              } | null;
            };
          };
        }
      | {
          __typename: 'EscrowOrder';
          id: string;
          message?: string | null;
          escrowScript: string;
          escrowAddress: string;
          nonce: string;
          releaseTxid?: string | null;
          returnTxid?: string | null;
          buyerDepositTx?: string | null;
          price: string;
          amount: number;
          amountCoinOrCurrency: number;
          createdAt: any;
          updatedAt: any;
          escrowOrderStatus: Types.EscrowOrderStatus;
          arbitratorAccount: {
            __typename?: 'Account';
            id: number;
            publicKey?: string | null;
            hash160?: string | null;
            telegramId?: string | null;
            telegramUsername?: string | null;
          };
          buyerAccount: {
            __typename?: 'Account';
            id: number;
            publicKey?: string | null;
            hash160?: string | null;
            telegramId?: string | null;
            telegramUsername?: string | null;
          };
          sellerAccount: {
            __typename?: 'Account';
            id: number;
            publicKey?: string | null;
            hash160?: string | null;
            telegramId?: string | null;
            telegramUsername?: string | null;
          };
          moderatorAccount: {
            __typename?: 'Account';
            id: number;
            publicKey?: string | null;
            hash160?: string | null;
            telegramId?: string | null;
            telegramUsername?: string | null;
          };
          paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          escrowOffer: {
            __typename?: 'Offer';
            postId: string;
            message: string;
            coinPayment?: string | null;
            localCurrency?: string | null;
          };
          escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
          dispute?: {
            __typename?: 'Dispute';
            id: string;
            createdBy: string;
            reason?: string | null;
            status: Types.DisputeStatus;
          } | null;
        }
      | {
          __typename: 'Post';
          id: string;
          content: string;
          accountId: number;
          pageId?: string | null;
          tokenId?: string | null;
          repostCount: number;
          totalComments: number;
          commentableId?: string | null;
          createdAt: any;
          updatedAt: any;
          followPostOwner?: boolean | null;
          followedPage?: boolean | null;
          followedToken?: boolean | null;
          bookmarkableId?: string | null;
          isBookmarked?: boolean | null;
          originalLanguage?: string | null;
          danaViewScore?: number | null;
          burnedByOthers?: boolean | null;
          account: {
            __typename?: 'Account';
            address: string;
            hash160?: string | null;
            publicKey?: string | null;
            id: number;
            name: string;
            avatar?: string | null;
            createCommentFee?: string | null;
            telegramUsername?: string | null;
          };
          page?: {
            __typename?: 'Page';
            avatar?: string | null;
            name: string;
            id: string;
            createPostFee: string;
            createCommentFee: string;
            pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
          } | null;
          token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
          reposts?: Array<{
            __typename?: 'Repost';
            accountId?: number | null;
            account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
          }> | null;
          dana?: {
            __typename?: 'PostDana';
            danaBurnUp: number;
            danaBurnDown: number;
            danaBurnScore: number;
            danaReceivedUp: number;
            danaReceivedDown: number;
            danaReceivedScore: number;
            version: number;
          } | null;
          boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
          translations?: Array<{
            __typename?: 'PostTranslation';
            id: string;
            translateContent?: string | null;
            translateLanguage?: string | null;
          }> | null;
          imageUploadable?: {
            __typename?: 'ImageUploadable';
            id: string;
            uploads: Array<{
              __typename?: 'Upload';
              id: string;
              sha: string;
              bucket?: string | null;
              width?: number | null;
              height?: number | null;
              cfImageId?: string | null;
              cfImageFilename?: string | null;
            }>;
          } | null;
          poll?: {
            __typename?: 'Poll';
            postId: string;
            question: string;
            startDate: any;
            endDate: any;
            canAddOption: boolean;
            singleSelect: boolean;
            defaultOptions?: Array<string> | null;
            totalVote?: number | null;
            options: Array<{
              __typename?: 'PollOption';
              id: string;
              option: string;
              pollId: string;
              danaScoreOption?: number | null;
              pollAnswerOnAccount?: Array<{
                __typename?: 'PollAnswerOnAccount';
                pollDanaScore: number;
                accountId: number;
              }> | null;
            }>;
          } | null;
          postOffer?: {
            __typename?: 'Offer';
            postId: string;
            publicKey: string;
            message: string;
            noteOffer?: string | null;
            price: string;
            coinPayment?: string | null;
            marginPercentage: number;
            localCurrency?: string | null;
            orderLimitMin: number;
            orderLimitMax: number;
            status: Types.OfferStatus;
            locationId?: string | null;
            paymentMethods: Array<{
              __typename?: 'OfferPaymentMethod';
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
            }>;
            location?: {
              __typename?: 'Location';
              id: string;
              iso2?: string | null;
              country?: string | null;
              adminNameAscii?: string | null;
              adminCode?: string | null;
              cityAscii?: string | null;
            } | null;
          } | null;
        };
  };
};

export type HomeTimelineQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  level?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type HomeTimelineQuery = {
  __typename?: 'Query';
  homeTimeline: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name?: string | null } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                  location?: {
                    __typename?: 'Location';
                    id: string;
                    iso2?: string | null;
                    country?: string | null;
                    adminNameAscii?: string | null;
                    cityAscii?: string | null;
                  } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              buyerDepositTx?: string | null;
              price: string;
              amount: number;
              amountCoinOrCurrency: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: {
                __typename?: 'Offer';
                postId: string;
                message: string;
                coinPayment?: string | null;
                localCurrency?: string | null;
              };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                id: string;
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                noteOffer?: string | null;
                price: string;
                coinPayment?: string | null;
                marginPercentage: number;
                localCurrency?: string | null;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                locationId?: string | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                location?: {
                  __typename?: 'Location';
                  id: string;
                  iso2?: string | null;
                  country?: string | null;
                  adminNameAscii?: string | null;
                  adminCode?: string | null;
                  cityAscii?: string | null;
                } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type ProfileTimelineQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id: Types.Scalars['Int']['input'];
}>;

export type ProfileTimelineQuery = {
  __typename?: 'Query';
  profileTimeline: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name?: string | null } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                  location?: {
                    __typename?: 'Location';
                    id: string;
                    iso2?: string | null;
                    country?: string | null;
                    adminNameAscii?: string | null;
                    cityAscii?: string | null;
                  } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              buyerDepositTx?: string | null;
              price: string;
              amount: number;
              amountCoinOrCurrency: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: {
                __typename?: 'Offer';
                postId: string;
                message: string;
                coinPayment?: string | null;
                localCurrency?: string | null;
              };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                id: string;
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                noteOffer?: string | null;
                price: string;
                coinPayment?: string | null;
                marginPercentage: number;
                localCurrency?: string | null;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                locationId?: string | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                location?: {
                  __typename?: 'Location';
                  id: string;
                  iso2?: string | null;
                  country?: string | null;
                  adminNameAscii?: string | null;
                  adminCode?: string | null;
                  cityAscii?: string | null;
                } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type ProfileTimelineByTimeQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id: Types.Scalars['Int']['input'];
  minimumDanaFilter: Types.Scalars['Int']['input'];
}>;

export type ProfileTimelineByTimeQuery = {
  __typename?: 'Query';
  profileTimelineByTime: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name?: string | null } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                  location?: {
                    __typename?: 'Location';
                    id: string;
                    iso2?: string | null;
                    country?: string | null;
                    adminNameAscii?: string | null;
                    cityAscii?: string | null;
                  } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              buyerDepositTx?: string | null;
              price: string;
              amount: number;
              amountCoinOrCurrency: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: {
                __typename?: 'Offer';
                postId: string;
                message: string;
                coinPayment?: string | null;
                localCurrency?: string | null;
              };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                id: string;
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                noteOffer?: string | null;
                price: string;
                coinPayment?: string | null;
                marginPercentage: number;
                localCurrency?: string | null;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                locationId?: string | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                location?: {
                  __typename?: 'Location';
                  id: string;
                  iso2?: string | null;
                  country?: string | null;
                  adminNameAscii?: string | null;
                  adminCode?: string | null;
                  cityAscii?: string | null;
                } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type PageTimelineQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id: Types.Scalars['String']['input'];
}>;

export type PageTimelineQuery = {
  __typename?: 'Query';
  pageTimeline: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name?: string | null } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                  location?: {
                    __typename?: 'Location';
                    id: string;
                    iso2?: string | null;
                    country?: string | null;
                    adminNameAscii?: string | null;
                    cityAscii?: string | null;
                  } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              buyerDepositTx?: string | null;
              price: string;
              amount: number;
              amountCoinOrCurrency: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: {
                __typename?: 'Offer';
                postId: string;
                message: string;
                coinPayment?: string | null;
                localCurrency?: string | null;
              };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                id: string;
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                noteOffer?: string | null;
                price: string;
                coinPayment?: string | null;
                marginPercentage: number;
                localCurrency?: string | null;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                locationId?: string | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                location?: {
                  __typename?: 'Location';
                  id: string;
                  iso2?: string | null;
                  country?: string | null;
                  adminNameAscii?: string | null;
                  adminCode?: string | null;
                  cityAscii?: string | null;
                } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type PageTimelineByTimeQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id: Types.Scalars['String']['input'];
  minimumDanaFilter: Types.Scalars['Int']['input'];
}>;

export type PageTimelineByTimeQuery = {
  __typename?: 'Query';
  pageTimelineByTime: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name?: string | null } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                  location?: {
                    __typename?: 'Location';
                    id: string;
                    iso2?: string | null;
                    country?: string | null;
                    adminNameAscii?: string | null;
                    cityAscii?: string | null;
                  } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              buyerDepositTx?: string | null;
              price: string;
              amount: number;
              amountCoinOrCurrency: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: {
                __typename?: 'Offer';
                postId: string;
                message: string;
                coinPayment?: string | null;
                localCurrency?: string | null;
              };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                id: string;
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                noteOffer?: string | null;
                price: string;
                coinPayment?: string | null;
                marginPercentage: number;
                localCurrency?: string | null;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                locationId?: string | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                location?: {
                  __typename?: 'Location';
                  id: string;
                  iso2?: string | null;
                  country?: string | null;
                  adminNameAscii?: string | null;
                  adminCode?: string | null;
                  cityAscii?: string | null;
                } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type TokenTimelineQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id: Types.Scalars['String']['input'];
}>;

export type TokenTimelineQuery = {
  __typename?: 'Query';
  tokenTimeline: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name?: string | null } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                  location?: {
                    __typename?: 'Location';
                    id: string;
                    iso2?: string | null;
                    country?: string | null;
                    adminNameAscii?: string | null;
                    cityAscii?: string | null;
                  } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              buyerDepositTx?: string | null;
              price: string;
              amount: number;
              amountCoinOrCurrency: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: {
                __typename?: 'Offer';
                postId: string;
                message: string;
                coinPayment?: string | null;
                localCurrency?: string | null;
              };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                id: string;
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                noteOffer?: string | null;
                price: string;
                coinPayment?: string | null;
                marginPercentage: number;
                localCurrency?: string | null;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                locationId?: string | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                location?: {
                  __typename?: 'Location';
                  id: string;
                  iso2?: string | null;
                  country?: string | null;
                  adminNameAscii?: string | null;
                  adminCode?: string | null;
                  cityAscii?: string | null;
                } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type TokenTimelineByTimeQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id: Types.Scalars['String']['input'];
  minimumDanaFilter: Types.Scalars['Int']['input'];
}>;

export type TokenTimelineByTimeQuery = {
  __typename?: 'Query';
  tokenTimelineByTime: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name?: string | null } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                  location?: {
                    __typename?: 'Location';
                    id: string;
                    iso2?: string | null;
                    country?: string | null;
                    adminNameAscii?: string | null;
                    cityAscii?: string | null;
                  } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              buyerDepositTx?: string | null;
              price: string;
              amount: number;
              amountCoinOrCurrency: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: {
                __typename?: 'Offer';
                postId: string;
                message: string;
                coinPayment?: string | null;
                localCurrency?: string | null;
              };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                id: string;
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                noteOffer?: string | null;
                price: string;
                coinPayment?: string | null;
                marginPercentage: number;
                localCurrency?: string | null;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                locationId?: string | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                location?: {
                  __typename?: 'Location';
                  id: string;
                  iso2?: string | null;
                  country?: string | null;
                  adminNameAscii?: string | null;
                  adminCode?: string | null;
                  cityAscii?: string | null;
                } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export const EscrowOrderFieldsFragmentDoc = `
    fragment EscrowOrderFields on EscrowOrder {
  id
  message
  arbitratorAccount {
    id
    publicKey
    hash160
    telegramId
    telegramUsername
  }
  buyerAccount {
    id
    publicKey
    hash160
    telegramId
    telegramUsername
  }
  sellerAccount {
    id
    publicKey
    hash160
    telegramId
    telegramUsername
  }
  moderatorAccount {
    id
    publicKey
    hash160
    telegramId
    telegramUsername
  }
  paymentMethod {
    id
    name
  }
  escrowOffer: offer {
    postId
    message
    coinPayment
    localCurrency
  }
  escrowScript
  escrowAddress
  nonce
  escrowTxids {
    txid
    value
    outIdx
  }
  dispute {
    id
    createdBy
    reason
    status
  }
  releaseTxid
  returnTxid
  buyerDepositTx
  price
  amount
  amountCoinOrCurrency
  escrowOrderStatus: status
  createdAt
  updatedAt
}
    `;
export const DisputeFieldsFragmentDoc = `
    fragment DisputeFields on Dispute {
  id
  escrowOrder {
    amount
    id
    buyerAccount {
      telegramUsername
    }
    sellerAccount {
      telegramUsername
    }
    offer {
      message
      country {
        name
      }
      state {
        name
      }
      location {
        id
        iso2
        country
        adminNameAscii
        cityAscii
      }
      createdAt
    }
  }
  reason
  createdBy
  disputeStatus: status
  createdAt
  updatedAt
}
    `;
export const TimelineItemFieldsFragmentDoc = `
    fragment TimelineItemFields on TimelineItem {
  id
  data {
    __typename
    ... on Post {
      ...PostFields
    }
    ... on EscrowOrder {
      ...EscrowOrderFields
    }
    ... on Dispute {
      ...DisputeFields
    }
  }
}
    ${PostFieldsFragmentDoc}
${EscrowOrderFieldsFragmentDoc}
${DisputeFieldsFragmentDoc}`;
export const TimelineDocument = `
    query Timeline($id: String!) {
  timeline(id: $id) {
    ...TimelineItemFields
  }
}
    ${TimelineItemFieldsFragmentDoc}`;
export const HomeTimelineDocument = `
    query HomeTimeline($after: String, $first: Int = 20, $level: Int) {
  homeTimeline(after: $after, first: $first, level: $level) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const ProfileTimelineDocument = `
    query ProfileTimeline($after: String, $first: Int = 20, $id: Int!) {
  profileTimeline(after: $after, first: $first, id: $id) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const ProfileTimelineByTimeDocument = `
    query ProfileTimelineByTime($after: String, $first: Int = 20, $id: Int!, $minimumDanaFilter: Int!) {
  profileTimelineByTime(
    after: $after
    first: $first
    id: $id
    minimumDanaFilter: $minimumDanaFilter
  ) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const PageTimelineDocument = `
    query PageTimeline($after: String, $first: Int = 20, $id: String!) {
  pageTimeline(after: $after, first: $first, id: $id) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const PageTimelineByTimeDocument = `
    query PageTimelineByTime($after: String, $first: Int = 20, $id: String!, $minimumDanaFilter: Int!) {
  pageTimelineByTime(
    after: $after
    first: $first
    id: $id
    minimumDanaFilter: $minimumDanaFilter
  ) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const TokenTimelineDocument = `
    query TokenTimeline($after: String, $first: Int = 20, $id: String!) {
  tokenTimeline(after: $after, first: $first, id: $id) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const TokenTimelineByTimeDocument = `
    query TokenTimelineByTime($after: String, $first: Int = 20, $id: String!, $minimumDanaFilter: Int!) {
  tokenTimelineByTime(
    after: $after
    first: $first
    id: $id
    minimumDanaFilter: $minimumDanaFilter
  ) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Timeline: build.query<TimelineQuery, TimelineQueryVariables>({
      query: variables => ({ document: TimelineDocument, variables })
    }),
    HomeTimeline: build.query<HomeTimelineQuery, HomeTimelineQueryVariables | void>({
      query: variables => ({ document: HomeTimelineDocument, variables })
    }),
    ProfileTimeline: build.query<ProfileTimelineQuery, ProfileTimelineQueryVariables>({
      query: variables => ({ document: ProfileTimelineDocument, variables })
    }),
    ProfileTimelineByTime: build.query<ProfileTimelineByTimeQuery, ProfileTimelineByTimeQueryVariables>({
      query: variables => ({ document: ProfileTimelineByTimeDocument, variables })
    }),
    PageTimeline: build.query<PageTimelineQuery, PageTimelineQueryVariables>({
      query: variables => ({ document: PageTimelineDocument, variables })
    }),
    PageTimelineByTime: build.query<PageTimelineByTimeQuery, PageTimelineByTimeQueryVariables>({
      query: variables => ({ document: PageTimelineByTimeDocument, variables })
    }),
    TokenTimeline: build.query<TokenTimelineQuery, TokenTimelineQueryVariables>({
      query: variables => ({ document: TokenTimelineDocument, variables })
    }),
    TokenTimelineByTime: build.query<TokenTimelineByTimeQuery, TokenTimelineByTimeQueryVariables>({
      query: variables => ({ document: TokenTimelineByTimeDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
